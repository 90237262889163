import * as React from "react"
import { ChangeEvent, useEffect, useState } from "react";
import axios from 'axios';
import Select from '@atlaskit/select';
import Tooltip from '@atlaskit/tooltip';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import mainStore from "../stores/mainStore";
import { observer } from "mobx-react-lite";
import workspaceService from "../services/workspaceService";


interface RepositoryProps {
    accessToken: string | null,
    clientKey: string;
    repository: any;
    updateCallback: (reposToUpdate: any[]) => void;
}

const ExpandedRepository = (props: RepositoryProps) => {

    const repoIsInitialized = props.repository.initialized;
    const repoIsActive = props.repository.active;
    const repoFullName = props.repository.full_name;
    const repoDefaultBranch = props.repository.defaultBranch;
    const repoCompressionType = props.repository.compressionType;
    const compressionLabel = (compressionType:string) => {
        if (compressionType==="lossless") {
            return "Lossless (Recommended)";
        } else if (compressionType==="lossy") {
            return "Lossy (~20% quality loss)";
        }
    };

    const [active, setActive] = useState<boolean>(repoIsActive);
    const [initializationModal, setInitializationModal] = useState<boolean>(false);
    const [branches, setBranches] = useState<any[]>([]);
    const [devBranch, setDevBranch] = useState<string | null>(null);
    const [selectedDefaultBranch, setSelectedDefaultBranch] = useState<{label: string, value: string} | null>({label: repoDefaultBranch!, value: repoDefaultBranch!});
    const [selectedCompressionType, setSelectedCompressionType] = useState<{label: string, value: string} | null>({label: compressionLabel(repoCompressionType)!, value: repoCompressionType});

    useEffect(() => {
        setSelectedDefaultBranch({label: repoDefaultBranch!, value: repoDefaultBranch!});
        getBranchesFromBitbucket();
    }, []);

    useEffect(() => {
        setSelectedDefaultBranch({label: devBranch!, value: devBranch!});
    }, [devBranch]);

    const updateDefaultBranch = async (branch: string) => {
        return new Promise(async (resolve, reject) => {
            const response:any = await axios.post(
                `${mainStore.apiUrl}/update-default-branch`, {
                  repository: JSON.stringify(props.repository),
                  clientKey: props.clientKey,
                  branch: branch
                }
            );
            if (response?.status===200) {
                setSelectedDefaultBranch({label: devBranch!, value: devBranch!});
                props.updateCallback([props.repository]);
            }
        });
    }

    const updateCompressionType = async (compressionType: string) => {
        return new Promise(async (resolve, reject) => {
            const response:any = await axios.post(
                `${mainStore.apiUrl}/update-compression-type`, {
                  repository: JSON.stringify(props.repository),
                  clientKey: props.clientKey,
                  compressionType: compressionType
                }
            );
            if (response?.status===200) {
                setSelectedCompressionType({label: compressionLabel(compressionType)!, value: compressionType});
                props.updateCallback([props.repository]);
            }
        });
    }

    const getBranchesFromBitbucket = async () => {
        return new Promise(async (resolve, reject) => {
            if (!props.accessToken) return;
            try {
                let nextPage;
                let branches:any[] = [];
                const branchesResponse:any = await axios.post(mainStore.proxyUrl, {
                    url: `https://api.bitbucket.org/2.0/repositories/${repoFullName}/refs/branches`,
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${props.accessToken}`,
                        'Accept': 'application/json'
                    }
                });
                console.log(branchesResponse);
                nextPage = branchesResponse?.data.next;
                branchesResponse?.data.values.forEach((branch :any) => {
                    branches.push(branch.name);
                });
                while (nextPage) {
                    const nextPageResponse:any = await axios.post(mainStore.proxyUrl, {
                        url: `https://api.bitbucket.org/2.0/repositories/${repoFullName}/refs/branches?page=${nextPage}`,
                        method: "GET",
                        headers: {
                            'Authorization': `Bearer ${props.accessToken}`,
                            'Accept': 'application/json'
                        }
                    });
                    branchesResponse?.data.values.forEach((branch :any) => {
                        branches.push(branch.name);
                    });
                    nextPage = nextPageResponse?.data.next;
                }
                console.log("BRANCHES 2");
                console.log(branches);
                if (branches.includes("autocompress")) {
                    const autocompressIndex = branches.indexOf("autocompress");
                    branches.splice(autocompressIndex, 1);
                }
                setBranches(branches);
                const branchingModelResponse:any = await axios.post(mainStore.proxyUrl, {
                    url: `https://api.bitbucket.org/2.0/repositories/${repoFullName}/branching-model`,
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${props.accessToken}`,
                        'Accept': 'application/json'
                    }
                });
                console.log("DEV BRANCH");
                console.log(branchingModelResponse?.data.development.name);
                setDevBranch(branchingModelResponse?.data.development.name);
                if (!repoDefaultBranch) {
                    updateDefaultBranch(branchingModelResponse.data.development.name);
                }
            } catch (e) {
                console.log(e);
            }
            resolve(true);
        });
      }

    const handleDefaultBranchChange = (newValue: any) => {
        if (newValue.value) {
            updateDefaultBranch(newValue.value);
        }
    };

    const handleCompressionTypeChange = (newValue: any) => {
        if (newValue.value) {
            updateCompressionType(newValue.value);
        }
    };

    return(
        <div style={{ paddingTop: 20, paddingLeft: 30, paddingBottom: 30, display: "flex", flexDirection: "column" }}> 
            <div style={{ display: "flex", flexDirection: "column", maxWidth: 350, marginRight: 30 }}>
                <label style={{ paddingBottom: 5,fontWeight: "bold", fontSize: 12, color: "rgb(107,119,140)" }} htmlFor="defaultBranch">Target branch</label>
                
                <div style={{ cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center", justifyItems: "center" }}>
                    <div style={{ flex: 1 }}>
                        <Select
                            defaultValue={selectedDefaultBranch}
                            isDisabled={branches.length < 1}
                            onChange={handleDefaultBranchChange}
                            label="defaultBranch"
                            appearance="default"
                            options={branches.map((branch) => {
                                return { label: branch, value: branch } 
                            })}
                        />
                    </div>
                    
                    <div style={{ width: 5 }}></div>
                    <Tooltip content={"Only images on this branch will be compressed"}>
                        <QuestionCircleIcon label="help" primaryColor="rgb(107,119,140)"/>
                    </Tooltip>
                </div>
                
                
                
            </div>
            <div style={{ height: 20 }}></div>
            <div style={{ display: "flex", flexDirection: "column", maxWidth: 350, marginRight: 30 }}>
                <label style={{ paddingBottom: 5,fontWeight: "bold", fontSize: 12, color: "rgb(107,119,140)" }} htmlFor="compressionType">Compression method</label>
                <div style={{ cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center", justifyItems: "center" }}>
                    
                    <div style={{ flex: 1 }}>
                    <Select
                        defaultValue={selectedCompressionType}
                        onChange={handleCompressionTypeChange}
                        label="compressionType"
                        appearance="default"
                        options={
                            [
                                { label: compressionLabel("lossless"), value: "lossless" },
                                { label: compressionLabel("lossy"), value: "lossy" }
                            ]
                        }
                    />
                    </div>
                    
                    <div style={{ width: 5 }}></div>
                    <Tooltip content={"Choose whether to sacrifice image quality to get smaller sizes"}>
                        <QuestionCircleIcon label="help" primaryColor="rgb(107,119,140)"/>
                    </Tooltip>
                </div>
            </div>
        </div>
    );

}

export default observer(ExpandedRepository);