import * as React from "react"
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import axios from 'axios';
import Toggle from '@atlaskit/toggle';
import Tooltip from '@atlaskit/tooltip';
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    ModalTransition,
  } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button/standard-button';
import Avatar from '@atlaskit/avatar';
import mainStore from "../stores/mainStore";
import { observer } from "mobx-react-lite";
import workspaceService from "../services/workspaceService";
import Lozenge from '@atlaskit/lozenge';
import Radium from 'radium';
import { IRepository } from "../models/repository";


interface RepositoryProps {
    accessToken: string | null,
    clientKey: string;
    repository: IRepository;
    maxReposReached: boolean;
    toggle: boolean,
    updateCallback: (reposToUpdate: any[]) => void;
}

const Repository = (props: RepositoryProps) => {

    const isBrowser = () => typeof window !== "undefined";

    const workspace = mainStore.getWorkspace(props.clientKey);
    const repository = mainStore.getRepository(props.clientKey, props.repository.uuid!);
    const repoIsActive = repository?.active;
    const repoFullName = repository?.full_name;
    const repoDefaultBranch = repository?.defaultBranch;
    const repoAvatar = repository?.avatar;
    const repoDisplayName = repository?.displayName;
    const repoCompressionType = repository?.compressionType;
    const isPrivate = repository?.is_private;
    const maxReposReached = workspace?.maxReposReached;

    const [isActive, setIsActive] = useState(false);
    const [activationLoading, setActivationLoading] = useState(false);
    const [initializationModal, setInitializationModal] = useState<boolean>(false);

    useEffect(() => {
        setIsActive(repoIsActive!);
    }, []);

    useEffect(() => {
        console.log("NOW!");
        console.log(workspace?.maxReposReached);
    }, [workspace?.maxReposReached]);

    const toggleRepoCompression = () => {
        if (repoIsActive) {
            deactivateRepo();
        } else {
            setInitializationModal(true);
        }
    }

    const toggleInitializationModal = (value: boolean) => {
        console.log("toggleInitializationModal");
        console.log(value);
        setInitializationModal(value);
    }

    const initializeRepo = async () => {
        console.log("Initialize!");
        // repository?.setActive();
        mainStore.toggleRepositoryActivation(props.clientKey, props?.repository?.uuid!)
        // mainStore.setRepositoriesForWorkspace([props.repository], props.clientKey);
        setActivationLoading(true);
        toggleInitializationModal(false);
        const response:any = axios.post(
            `${mainStore.apiUrl}/initialize-repo`, {
              repository: JSON.stringify(props.repository),
              clientKey: props.clientKey
            }
        ).then(() => {
            setIsActive(true);
            setActivationLoading(false);
            console.log(props.repository);
            setTimeout(function(){
                props.updateCallback([props.repository]);
            }, 750);
        }).catch((error) => {
            console.log(error);
            setIsActive(false);
            setActivationLoading(false);
            console.log(props.repository);
            setTimeout(function(){
                props.updateCallback([props.repository]);
            }, 750);
        });
    }

    const deactivateRepo = async () => {
        console.log("Deactivate!");
        // repository?.setInactive();
        mainStore.toggleRepositoryActivation(props.clientKey, props.repository.uuid!)
        // mainStore.setRepositoriesForWorkspace([props.repository], props.clientKey);
        setActivationLoading(true);
        const response:any = await axios.post(
            `${mainStore.apiUrl}/deactivate-repo`, {
              repository: JSON.stringify(props.repository),
              clientKey: props.clientKey
            }
        ).then(() => {
            setIsActive(false);
            setActivationLoading(false);
            console.log(props.repository);
            setTimeout(function(){
                props.updateCallback([props.repository]);
            }, 750);
        }).catch((error) => {
            console.log(error);
            setIsActive(true);
            setActivationLoading(false);
            console.log(props.repository);
            setTimeout(function(){
                props.updateCallback([props.repository]);
            }, 750);
        });
    }

    const closeInitModal = useCallback(() => toggleInitializationModal(false), []);
    const submitInitModal = useCallback(() => initializeRepo(), []);

    const [width, setWidth] = React.useState(isBrowser() ? window.innerWidth / 1.5 : "66%");

    const toggleTooltip = () => {
        if (props.maxReposReached && !isActive && isPrivate) {
            return "Upgrade to compress more private repos";
        }
        if (isActive) {
            return "Click to stop compressing";
        } else {
            return "Click to start compressing";
        }
    }

    useEffect(() => {
        const handleWindowResize = () => setWidth(isBrowser() ? window.innerWidth / 1.5 : "66%")
        isBrowser() && window.addEventListener("resize", handleWindowResize);
        
        if (isBrowser())
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    const hideOnMobile = {
        "@media (max-width: 800px)": {
            display: "none"
          }
    };

    return(
        <>
        
            <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    position: "relative",
                    // backgroundColor: props.toggle ? "red" : "blue"
                }}>
                    {!props.toggle ? <>
                        <Avatar appearance="square" size="xsmall" src={repoAvatar} name="Repo" />
                        <div style={{maxWidth: "80%", paddingLeft: 5, paddingRight: 5, color: "#172B4D", fontSize: 14, fontWeight: "bold",}}>{repoDisplayName}</div>
                        <div style={{position: "relative",textOverflow: "ellipsis",  whiteSpace:"nowrap", marginLeft: 10, paddingLeft: 10, paddingRight: 10, color: "rgb(107,119,140)", fontSize: 12, fontWeight: "bold", background: "#F4F5F7", borderRadius: 10, ...hideOnMobile}}>{repoFullName}</div>

                        {isPrivate ? null :
                        <div style={{position: "relative",textOverflow: "ellipsis",  whiteSpace:"nowrap", marginLeft: 10, paddingLeft: 10, paddingRight: 10, color: "#0052CC", fontSize: 12, fontWeight: "bold", background: "#DEEBFF", borderRadius: 10, ...hideOnMobile}}>
                            Public
                        </div>
                        }
                        {/* {isInitialized ? <div style={{position: "relative",textOverflow: "ellipsis",  whiteSpace:"nowrap", marginLeft: 10, paddingLeft: 10, paddingRight: 10, color: "#fff", fontSize: 12, fontWeight: "bold", background: "#7EC0AA", borderRadius: 10, ...hideOnMobile}}>
                            Compressing all images. You will receive a PR in 10 minutes.
                        </div> :
                        null
                        } */}
                    </> : activationLoading
                ? <div style={{ 
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "center",
                    justifyItems: "center",
                    justifyContent: "center",
                    height: 20,
                    }}>
                    <label style={{
                        color: "#7EC0AA",
                        fontSize: 12,
                        fontWeight: "bold",
                        paddingRight: 10,
                        ...hideOnMobile
                    }} htmlFor="toggle-controlled">
                        {'Initializing...'}
                    </label>
                    <Toggle
                            isDisabled={true}
                            size="large"
                            id="toggle-controlled"
                            onChange={toggleRepoCompression}
                            isChecked={isActive}
                            defaultChecked={repoIsActive}
                        /> 
                </div>
                : <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "center",
                    justifyItems: "center",
                    justifyContent: "center",
                    height: 20,
                    }}>
                        
                    <label style={{
                        color: isActive ? "#7EC0AA" : "#DFE1E6",
                        fontSize: 12,
                        fontWeight: "bold",
                        paddingRight: 10,
                        ...hideOnMobile
                    }} htmlFor="toggle-controlled">
                        {isActive
                            ? 'Compressing'
                            : 'Inactive'}
                    </label>
                    <Tooltip
                        content={toggleTooltip}
                    >
                        <Toggle
                            size="large"
                            id="toggle-controlled"
                            onChange={toggleRepoCompression}
                            isChecked={isActive}
                            defaultChecked={repoIsActive}
                            isDisabled={!isActive && maxReposReached && isPrivate}
                        /> 
                    </Tooltip>
                </div>}
            </div>

            <ModalTransition>
                {initializationModal && (
                <Modal onClose={closeInitModal}>
                    <ModalHeader>
                    <ModalTitle>Initialize repository</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                    Autocompress will now optimize all the images in branch{' '}
                    <span style={{
                        fontWeight: "bold",
                        color: "#0052CC",
                        backgroundColor:"#a2bade",
                        paddingLeft: 5,
                        paddingRight: 5,
                        borderRadius: 5,
                        paddingBottom: 2,
                        fontSize: 14
                        }}>{repoDefaultBranch}</span>
                    {' '}with a{' '}
                    <span style={{
                                                fontWeight: "bold",

                        color: "#0052CC",
                        backgroundColor:"#a2bade",
                        paddingLeft: 5,
                        paddingRight: 5,
                        borderRadius: 5,
                        paddingBottom: 2,
                        fontSize: 14
                        }}>{repoCompressionType}</span>
                    {' '} compression algorithm and create the first Pull Request.
                    <br/><br/>In the future, only new or modified images will be compressed.
                    
                    </ModalBody>
                    <ModalFooter>
                    <Button appearance="subtle" onClick={closeInitModal}>
                        Cancel
                    </Button>
                    <Button appearance="primary" onClick={submitInitModal} autoFocus>
                        Start compression
                    </Button>
                    </ModalFooter>
                </Modal>
                )}
            </ModalTransition>
        </>
        
    );

}

export default observer(Radium(Repository));