import * as React from "react"
import { useEffect, useState } from "react";
import { navigate } from "gatsby";
import axios from 'axios';
import Workspace from "../components/workspace";
import {
  Content,
  PageLayout,
  TopNavigation,
} from '@atlaskit/page-layout';
import NavBar from "../components/navbar";
import PageHeader from '@atlaskit/page-header';
import Spinner from '@atlaskit/spinner';
import Button from '@atlaskit/button/standard-button';
import { BitbucketIcon } from '@atlaskit/logo';
import mainStore from "../stores/mainStore";
import "../styles.css";
import { observer } from "mobx-react-lite";
import authService from "../services/authService";
import { IAccessData } from "../models/accessData";
import workspaceService from "../services/workspaceService";
import ButtonGroup from '@atlaskit/button/button-group';


const App = () => {

  const isBrowser = () => typeof window !== "undefined";
  let accessData: IAccessData = mainStore.accessData;

  const workspaces = mainStore.workspaces;
  const [ownedClientKeys, setOwnedClientKeys] = useState<string[]>([]);

  useEffect(() => {
    console.log("HELLOOO 111");
  }, [mainStore.workspaces]);

  useEffect(() => {
    const initialize = async () => {
      if (!isBrowser()) return;
      
      if (!accessData.accessToken) {
        let params = new URLSearchParams(document.location.search);
        let code = params.get("code");
        
        const previousCode = localStorage.getItem("previous_code");
        if (code) {
          if (code!==previousCode) {
            localStorage.setItem("previous_code", code);
            if (!await authService.getAccessTokenFromAuth(code)) {
              navigate("/");
              return;
            }
          } else {
            if (!await authService.getAccessTokenFromRefresh()) {
              navigate("/");
              return;
            }
          }
        } else {
					if (!await authService.getAccessTokenFromRefresh()) {
						navigate("/");
						return;
					}
        }
      }
      // if (!await authService.getAccessTokenFromRefresh()) {
      //   navigate("/");
      //   return;
      // }
      await authService.getUserFromBitbucket();
      const repos = await getRepositoriesFromBitbucket();
      await workspaceService.getWorkspacesFromAutocompress(repos as any[]);
      setOwnedClientKeys(await workspaceService.getClientKeysFromOwnedWorkspaces());
      retryGettingWorkspaces();
      setLoading(false);
    }
    initialize();    
  }, []);

  const retryGettingWorkspaces = () => {
    const workspacesCount = mainStore.workspaces.length;
    let params = new URLSearchParams(document.location.search);
    let code = params.get("code");
    if (code || workspacesCount == 0) {
      let counter = 0;
      const interval = setInterval(async function(){ 
        counter++;
        const repos = await getRepositoriesFromBitbucket();
        await workspaceService.getWorkspacesFromAutocompress(repos as any[]);
        setOwnedClientKeys(await workspaceService.getClientKeysFromOwnedWorkspaces());
        if (mainStore.workspaces.length > workspacesCount || mainStore.workspaces.length < workspacesCount) {
          clearInterval(interval);
          return;
        }
        if (counter > 10) {
          clearInterval(interval);
          return;
        }
      }, 1000);//run this thang every 2 seconds
    }
  }

  const filterReposWithAdminPermission = async (response:any) => {
    return new Promise(async (resolve, reject) => {
      let newRepositoriesList:any[] = [];
      response.data.values.forEach((value:any) => {
        if (value.permission == "admin") {
          const repository:any = value.repository;
          newRepositoriesList.push(repository);
        }
      });
      resolve(newRepositoriesList);
    });
  }

  const getRepositoriesFromBitbucket = async () => {
    return new Promise(async (resolve, reject) => {
      let nextPage;
      const response:any = await axios.post(mainStore.proxyUrl, {
        url: `https://api.bitbucket.org/2.0/user/permissions/repositories`,
        method: "GET",
        headers: {
          'Authorization': `Bearer ${accessData.accessToken}`,
          'Accept': 'application/json'
        }
      });
      let repos:any[] = await filterReposWithAdminPermission(response) as any[];
      nextPage = response.data.next;
      while (nextPage) {
        const nextPageResponse:any = await axios.post(mainStore.proxyUrl, {
          url: `https://api.bitbucket.org/2.0/user/permissions/repositories?page=${nextPage}`,
          method: "GET",
          headers: {
            'Authorization': `Bearer ${accessData.accessToken}`,
            'Accept': 'application/json'
          }
        });
        const filteredRepos:any[] = await filterReposWithAdminPermission(nextPageResponse) as any[];
        repos.push(...filteredRepos);
        nextPage = nextPageResponse.data.next;
      }
      resolve(repos);
    });
  }

  const [width, setWidth] = useState(isBrowser() ? window.innerWidth / 1.5 : "66%");
  const [loading, setLoading] = useState(true);
  const atlassianMarketplaceURL = mainStore.installationUrl;

  useEffect(() => {
      const handleWindowResize = () => setWidth(isBrowser() ? window.innerWidth / 1.5 : "66%")
      isBrowser() && window.addEventListener("resize", handleWindowResize);

      if (isBrowser())
      return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const launchInstallationUrl = () => {
    isBrowser() && window.open(mainStore.installationUrl,"_self");
  }

  return (
    <PageLayout>
        <TopNavigation
            testId="topNavigation"
            id="product-navigation"
            skipLinkTitle="Product Navigation"
            height={60}
            isFixed={false}
        >
            <NavBar/>
        </TopNavigation>
        <Content testId="content">
          <div style={{
            position: "relative", 
            display: "flex",
            margin: "auto",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            maxWidth: 1200,
            width:"90%",
            padding: 20
            }}>
            <PageHeader>
                {workspaces.length < 1 && !loading ? "Autocompress is not installed!" : "Compress your repositories"}
              </PageHeader>
              {
                workspaces.length > 0
                ? workspaces.map((workspace, index) => {

                    return <Workspace
                      key={index}
                      accessToken={mainStore.accessData.accessToken}
                      workspace={workspace}
                      owned={ownedClientKeys.includes(workspace.clientKey!)}
                    />
                  })
                : loading
                  ? <div><Spinner size={30} interactionName="load" /></div>
                  : <div style={{display: "flex", flexDirection: "column", width: "70%" }}>
                      <div> To use Autocompress you first need to install it on at least one workspace. Click the button below:</div>
                      <div style={{ marginTop: 15, marginBottom: 15}}>
                        <Button appearance="primary" iconBefore={<BitbucketIcon appearance="inverse" label="" size="small" />} onClick={launchInstallationUrl}
                        >Install on Bitbucket</Button>
                      </div>
                      <div> Or send this link to one of your workspace admins so they can install it: </div>
                      <div style={{
                        marginTop: 15,
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center"}}
                        >
                          <div style={{height: 25, paddingLeft: 10, paddingRight: 10, paddingTop: 5, color: "rgb(107,119,140)", fontSize: 12, fontWeight: "bold", background: "#F4F5F7", borderRadius: 10}}>{atlassianMarketplaceURL}</div>
                          <Button sizes="" spacing="compact" appearance="subtle" onClick={() => navigator.clipboard.writeText(atlassianMarketplaceURL)} size={5}>
                            Copy URL
                          </Button>
                      </div>
                    </div>
              }
              <div style={{height: 5}}></div>
              {workspaces.length > 0 && !loading
              ? <Button appearance="default" iconBefore={<BitbucketIcon appearance="neutral" label="" size="small" />} onClick={launchInstallationUrl}
              >Add a new workspace</Button>
                : null}
              
          </div>
          
        </Content>
    </PageLayout>
  )
}

export default observer(App);

export const Head = () => <title>Autocompress</title>