import * as React from "react"
import { useEffect, useState } from "react";
import ExpandedRepository from "./expandedRepository";
import TableTree, { Cell, Header, Headers, Row, Rows } from '@atlaskit/table-tree';
import Button from '@atlaskit/button/standard-button';
import Repository from "./repository";
import StarFilledIcon from '@atlaskit/icon/glyph/star-filled';
import ProgressBar from '@atlaskit/progress-bar';
import { observer } from "mobx-react-lite";
import workspaceService from "../services/workspaceService";
import { navigate } from "gatsby";
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    ModalTransition,
  } from '@atlaskit/modal-dialog';
  import CrossIcon from '@atlaskit/icon/glyph/cross';
  import { IWorkspace } from "../models/workspace";
import { Card } from "@material-ui/core";
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Banner from '@atlaskit/banner';
import Radium from "radium";
import mainStore from "../stores/mainStore";
import { IRepository } from "../models/repository";

interface WorkspaceProps {
    accessToken: string | null,
    workspace: any;
    owned: boolean;
}

const Workspace = (props: WorkspaceProps) => {

    const isBrowser = () => typeof window !== "undefined";
    const Paddle = isBrowser() && window.Paddle;

    if (Paddle) {
      if (!mainStore.isProduction) Paddle.Environment.set("sandbox");
      Paddle.Setup({ vendor: parseInt(mainStore.paddleVendorId) });
    }

    const workspace = mainStore.getWorkspace(props.workspace.clientKey);
    const repositories = workspace?.repositories;
    const subscription = workspace?.subscription;
    const selectedRepos = workspace?.selectedRepos;

    const [repositoriesTable, setRepositoriesTable] = useState<any[] | undefined>([]);

    useEffect(() => {
        console.log("HELLOOO");
    }, [workspace?.repositories]);

    useEffect(() => {

        const planId = localStorage.getItem("selected_plan");
        const clientKey = localStorage.getItem("clientKey");

        if (planId && clientKey) {
            if (clientKey === workspace?.clientKey) {
                localStorage.removeItem("selected_plan");
                localStorage.removeItem("clientKey");
                Paddle.Checkout.open({
                    product: parseInt(planId),
                    customData: {
                        "clientKey": clientKey,
                    }
                });
            }
        }

        setRepositoriesTable(
            repositories?.map((repo:IRepository) => ({
                index: repositories.indexOf(repo),
                full_name: repo.full_name,
                active: repo.active,
                uuid: repo.uuid,
                compressionType: repo.compressionType,
                defaultBranch: repo.defaultBranch,
                avatar: repo.avatar,
                displayName: repo.displayName,
                is_private: repo.is_private,
                expanded: {
                    index: repositories.indexOf(repo) + 0.1,
                    full_name: repo.full_name,
                    avatar: repo.avatar,
                    displayName: repo.displayName,
                    is_private: repo.is_private,
                    active: repo.active,
                    uuid: repo.uuid,
                    compressionType: repo.compressionType,
                    defaultBranch: repo.defaultBranch,
                    expanded: null
                }
            }))
        );
    }, []);

    useEffect(() => {
        setRepositoriesTable(
            repositories?.map((repo:IRepository) => ({
                index: repositories.indexOf(repo),
                full_name: repo.full_name,
                active: repo.active,
                uuid: repo.uuid,
                compressionType: repo.compressionType,
                defaultBranch: repo.defaultBranch,
                avatar: repo.avatar,
                displayName: repo.displayName,
                is_private: repo.is_private,
                expanded: {
                    index: repositories.indexOf(repo) + 0.1,
                    full_name: repo.full_name,
                    active: repo.active,
                    uuid: repo.uuid,
                    avatar: repo.avatar,
                    displayName: repo.displayName,
                    is_private: repo.is_private,
                    compressionType: repo.compressionType,
                    defaultBranch: repo.defaultBranch,
                    expanded: null
                }
            }))
        );
    }, [repositories, workspace?.repositories]);

    const updateCallback = async (reposToUpdate:IRepository[]) => {
        // let allRepos = repositoriesTable?.concat(reposToUpdate);
        // allRepos = [...new Set([...repositoriesTable!,...reposToUpdate])]
        workspaceService.getWorkspacesFromAutocompress(reposToUpdate);
    }

    const [upgradeModal, setUpgradeModal] = useState<boolean>(false);
    const [selectedWorkspace, setSelectedWorkspace] = useState<IWorkspace | undefined>(undefined);

    const upgrade = (workspace: IWorkspace) => {
        setSelectedWorkspace(workspace);
        setUpgradeModal(true);
    }

    const openCheckout = async (planId: number) => {
        setUpgradeModal(false);
        Paddle.Checkout.open({
            product: planId,
            customData: {
            "clientKey": selectedWorkspace?.clientKey,
            }
        });
    }

    return(
        <>
        <div style={{display: "flex", flexDirection: "column", width: "100%" }}>
            <div style={{alignItems: "center", paddingBottom: 20, display: "flex", flexDirection: "row", width: "100%", height: 15}}>
                <div style={{color: "#0052CC", fontSize: 14, paddingRight: 10}}>{workspace?.name}</div>
                <div style={{color: "#5E6E84", fontSize: 14, fontStyle: "italic"}}>{subscription?.planName}</div>
                <div style={{marginLeft:'auto', display: "flex", flexDirection: "row", alignItems: "center", justifyItems: "flex-end", justifyContent: "flex-end",width: "50%"}}>
                    {subscription?.subscribed ? <div style={{color: "#42526E", fontSize: 12, fontWeight: "bold" }}>{selectedRepos?.toString()} / {subscription?.maxRepos} private repositories</div> : null}
                    {subscription?.subscribed ? 
                        <div style={{width: "50%", maxWidth: 400, alignItems: "center", paddingLeft: 10, paddingRight: 10}}>
                            <ProgressBar ariaLabel={`${selectedRepos?.toString()} / ${subscription?.maxRepos}  private repositories`} value={(selectedRepos && subscription?.maxRepos) ? selectedRepos / subscription?.maxRepos : 0} />
                        </div>
                    : null}
                    
                    {props.owned ? <div ><Button iconBefore={<StarFilledIcon label="" size="small" />} spacing="compact" appearance="primary" onClick={() => upgrade(workspace!)} size={5}>
                        <div style={{fontSize: 12}}>Upgrade</div>
                    </Button></div> : null}
                </div>
                
            </div>
            {
                !subscription?.subscribed ? <div style={{ paddingBottom: 20}}>
                    <Banner
                        appearance="announcement"
                        icon={<WarningIcon label="" secondaryColor="inherit" />}
                    >
                        {props.owned ? "Upgrade to a paid plan to compress private repositories: " : "Need to compress private repos? Ask your admins to upgrade to a paid plan."}
                        {props.owned ? <a style={{ cursor: "pointer" }} onClick={() => upgrade(workspace!)}>Upgrade</a> : null}
                    </Banner>
                </div> : null
            }
            {
                subscription?.status==="past_due" ? <div style={{ paddingBottom: 20}}>
                    <Banner
                        appearance="warning"
                        icon={<WarningIcon label="" secondaryColor="inherit" />}
                    >
                        There was a problem with your last payment.{" "}
                        {props.owned ? <a style={{ cursor: "pointer" }} onClick={() => {
                            Paddle.Checkout.open({
                                override: selectedWorkspace?.subscription.update_url,
                              });
                        }}>Update your payment details</a> : "Notify your admins"}
                        
                        {" "} to keep using Autocompress.
                    </Banner>
                </div> : null
            }
            {
                subscription?.status==="paused" ? <div style={{ paddingBottom: 20}}>
                    <Banner
                        appearance="warning"
                        icon={<WarningIcon label="" secondaryColor="inherit" />}
                    >
                        Your subscription has been paused.{" "}
                        {props.owned ? <a style={{ cursor: "pointer" }} onClick={() => {
                            Paddle.Checkout.open({
                                override: selectedWorkspace?.subscription.update_url,
                              });
                        }}>Update your payment details</a> : "Notify your admins"}
                        {" "} to keep using Autocompress.
                    </Banner>
                </div> : null
            }
            
            <div style={{ border: "1px solid rgba(0, 0, 0, 0.1)", borderRadius: 6 }}>
                <TableTree>
                    <Rows
                        items={repositoriesTable}
                        render={(repo: IRepository) => {
                            if (repo.expanded) {
                                return(
                                    <Row
                                        itemId={repo.index}
                                        hasChildren={repo.expanded}
                                        items={[repo.expanded]}>
                                        <Cell width={1500}>
                                            <Radium.StyleRoot>
                                                <Repository
                                                    key={repo.index}
                                                    accessToken={props.accessToken}
                                                    clientKey={props.workspace.clientKey}
                                                    repository={repo}
                                                    maxReposReached={selectedRepos === subscription?.maxRepos}
                                                    toggle={false}                                                
                                                    updateCallback={updateCallback}
                                                />
                                            </Radium.StyleRoot>
                                            
                                        </Cell>
                                        <Cell>
                                            <Radium.StyleRoot>
                                                <Repository
                                                    key={repo.index}
                                                    accessToken={props.accessToken}
                                                    clientKey={props.workspace.clientKey}
                                                    repository={repo}
                                                    maxReposReached={selectedRepos === subscription?.maxRepos}
                                                    toggle={true}                                                
                                                    updateCallback={updateCallback}
                                                />
                                            </Radium.StyleRoot>
                                            
                                        </Cell>
                                    </Row>
                                )
                            } else {
                                return (
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <ExpandedRepository
                                            key={repo.index}
                                            accessToken={props.accessToken}
                                            clientKey={props.workspace.clientKey}
                                            repository={repo}
                                            updateCallback={updateCallback}
                                        />
                                        <div style={{ height: 0.5, width: "100%", background: "rgba(0, 0, 0, 0.1)" }}></div>

                                    </div>
                                    
                                )
                            }
                            
                        }}
                    />
                </TableTree>
            </div>
            <div style={{ height: 25 }}></div>
        </div>
        <ModalTransition>
                {upgradeModal && (
                <Modal width="x-large" onClose={() => setUpgradeModal(false)} shouldScrollInViewport={true}>
                    <ModalHeader>
                      <ModalTitle></ModalTitle>
                      <Button appearance="subtle" onClick={() => setUpgradeModal(false)}>
                        <CrossIcon
                          label="Close Modal"
                        />
                      </Button>
                    </ModalHeader>
        <ModalBody>
                    <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{
              position: "relative", 
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: 1500,
              width: "100%",
            }}>
                <h1 style={{ textAlign:"center", minWidth: 300, width: "60%", color: "#253858", fontWeight: "bold", fontSize: 35 }}>Start with a free 14-day trial</h1>
                <span style={{ textAlign:"center", minWidth: 250, width: "60%", color: "#253858", fontSize: 14, fontWeight: "normal", paddingLeft: 10, paddingRight: 10 }}>
                  Plans are based on the number of private repositories being compressed, while compression is always free for public repositories. Each plan covers only one workspace (ie. if you need to compress repos in 2 workspaces, you will need 2 subscriptions). <br/>
                </span>
                <div style={{
                  paddingTop: 30,
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: 'wrap',
                  width: "100%",
                  alignItems: "start",
                  justifyContent: "center"
                  }}>
                    {mainStore.paddlePlans.map((plan: any) => {
                            if (selectedWorkspace?.subscription.plan?.subscription_plan_id!==plan.id.toString()) {
                              return <Card elevation={2} style={{ margin: 10}}>
                                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start", padding: 10 }}>
                                    <img src={plan.img} style={{ margin: "auto", maxWidth: 150}}></img>
                                  <h3 style={{ color: "#253858", fontWeight: "normal", fontSize: 32, textAlign: "center" }}>{`${plan.name}`}</h3>
                                  <span style={{ color: "#253858", fontSize: 16, fontWeight: "normal", textAlign: "center" }}>
                                    {`Up to ${plan.repos} repositories`}<br/>
                                    {`Unlimited users`}<br/>
                                  </span>
                                  <span style={{ color: "#253858", margin: 20, fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
                                  {`${plan.price}/month`}
                                  </span>
                                  <Button appearance="primary" onClick={() => openCheckout(plan.id)}
                                  style={{ margin: 20,  width: "80%" }}
                                  >{"Upgrade"}</Button>
                                </div>
                              </Card>
                            }
                        })}
                </div>
            <div style={{ height: 30 }}></div>
            <span style={{ color: "#4A6798", fontSize: 16, fontWeight: "normal", textAlign: "center" }}>
              * Prices on USD. Taxes may apply.<br/>Secure payment via Paddle.
            </span>
            <div style={{ height: 30 }}></div>
            
          </div>
        </div>
                    {/* <span style={{ color: "#253858", fontSize: 16, fontWeight: "normal" }}>
                      Plans are based on the number of repositories that need compressing. <br/>
                    </span>
                      <div style={{
                        paddingTop: 20,
                        paddingBottom: 20,
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "start",
                        justifyContent: "center"
                        }}>
                          {mainStore.paddlePlans.map((plan: any) => {
                            if (selectedWorkspace?.subscription.plan?.subscription_plan_id!==plan.id.toString()) {
                              return <Card elevation={2} style={{ margin: 10}}>
                                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start", padding: 10 }}>
                                  <h3 style={{ color: "#253858", fontWeight: "normal", fontSize: 32, textAlign: "center" }}>{`${plan.name}`}</h3>
                                  <span style={{ color: "#253858", fontSize: 16, fontWeight: "normal", textAlign: "center" }}>
                                    {`Up to ${plan.repos} repositories`}<br/>
                                    {`Unlimited users`}<br/>
                                  </span>
                                  <span style={{ color: "#253858", margin: 20, fontSize: 20, fontWeight: "bold", textAlign: "center" }}>
                                  {`${plan.price}/month`}
                                  </span>
                                  <Button appearance="primary" onClick={() => console.log(plan.id)}
                                  style={{ margin: 20,  width: "80%" }}
                                  >{"Upgrade"}</Button>
                                </div>
                              </Card>
                            }
                        })}
                      </div> */}
                    </ModalBody>
                    {/* <ModalFooter>
                    <Button appearance="subtle" onClick={closeInitModal}>
                        Cancel
                    </Button>
                    <Button appearance="primary" onClick={submitInitModal} autoFocus>
                        {mainStore.user ? "Upgrade" : "Subscribe"}
                    </Button>
                    </ModalFooter> */}
                </Modal>
                )}
          </ModalTransition>
        </>
        
        
    );

}

export default observer(Radium(Workspace));